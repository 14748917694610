import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import OAuthFinished from '@noloco/core/src/components/OAuthFinished';
import { CURRENT_USER_BILLING_QUERY } from '@noloco/core/src/queries/auth';
import {
  setBillingPlan,
  setBillingPlanUsage,
} from '@noloco/core/src/reducers/billingPlan';
import { billingPlanSelector } from '@noloco/core/src/selectors/billingPlanSelectors';
import { useDashboardAuth } from '@noloco/core/src/utils/hooks/useAuth';
import useHasFeatureFlag, {
  CORE_MAINTENANCE_MODE,
} from '@noloco/core/src/utils/hooks/useHasFeatureFlag';
import useIsTrialExpired from '@noloco/core/src/utils/hooks/useIsTrialExpired';
import { getText } from '@noloco/core/src/utils/lang';
import { setTeamsUsage } from '../reducers/teamsUsage';
import CloneProject from './CloneProject';
import ForgotPassword from './ForgotPassword';
import FullstoryRecording from './FullstoryRecording';
import Join from './Join';
import Login from './Login';
import MaintenanceMode from './MaintenanceMode';
import NewProjectWrapper from './NewProjectWrapper';
import Onboarding from './Onboarding';
import Profile from './Profile';
import ProjectDemo from './ProjectDemo';
import ProjectRedirect from './ProjectRedirect';
import Register from './Register';
import ResetPassword from './ResetPassword';
import WorkspaceBilling from './WorkspaceBilling';
import WorkspaceProjectList from './WorkspaceProjectList';
import WorkspaceSettingsList from './WorkspaceSettingsList';
import DashboardWrapper from './dashboard/DashboardWrapper';
import GSheetPicker from './dashboard/GSheetPicker';
import Referrals from './referrals/Referrals';

const App = () => {
  const auth = useDashboardAuth();
  const dispatch = useDispatch();
  const billingPlan = useSelector(billingPlanSelector);

  const coreMaintenanceModeEnabled = useHasFeatureFlag(CORE_MAINTENANCE_MODE);

  const { data: usageData } = useQuery(CURRENT_USER_BILLING_QUERY, {});
  useEffect(() => {
    if (
      billingPlan &&
      !billingPlan.usage &&
      usageData &&
      usageData.currentUser?.user
    ) {
      dispatch(setBillingPlanUsage(usageData.currentUser.user.team.plan.usage));
      const teams = usageData.currentUser.user.teams;
      dispatch(setTeamsUsage(teams));
    }
  }, [billingPlan, dispatch, usageData]);

  useEffect(() => {
    const userBillingPlan = get(auth, 'user.team.plan');
    if (userBillingPlan && !billingPlan) {
      dispatch(setBillingPlan(userBillingPlan));
    }
  }, [auth, billingPlan, dispatch]);

  const trialHasExpired = useIsTrialExpired();

  if (coreMaintenanceModeEnabled) {
    return <MaintenanceMode />;
  }

  const app = (
    <div className="App min-h-screen bg-slate-800">
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/onboarding" component={Onboarding} />
        {!trialHasExpired && (
          <Route exact path="/new" component={NewProjectWrapper} />
        )}
        <Route exact path="/forgot" component={ForgotPassword} />
        <Route exact path="/forgot/:resetToken" component={ResetPassword} />
        <Route
          exact
          path="/join/:invitationToken"
          component={() => <Join showOnboarding={false} />}
        />
        <Route
          exact
          path="/invite/:invitationToken"
          component={() => <Join showOnboarding={true} />}
        />
        <Route exact path="/_oauth/finished" component={OAuthFinished} />
        <Route exact path="/_pick-gsheet" component={GSheetPicker} />
        <Route path="/p/:projectId" component={ProjectRedirect} />
        <Route path="/t/:projectId" component={ProjectDemo} />
        <Route>
          <DashboardWrapper>
            <Switch>
              <Route
                exact
                path="/billing/:workspace?"
                component={WorkspaceBilling}
              />
              <Route exact path="/profile" component={Profile} />
              <Route exact path="/refer" component={Referrals} />
              <Route exact path="/clone/:projectId" component={CloneProject} />
              <Route
                path="/workspaces/:workspace?"
                component={WorkspaceSettingsList}
              />
              <Route
                path="/apps/:workspace?"
                component={WorkspaceProjectList}
              />
              <Route path="/" component={WorkspaceProjectList} />
              <Route>
                <div className="m-0 flex w-full flex-col items-center justify-center py-56 text-center">
                  <h2 className="mb-2 mt-8 text-2xl">
                    {getText('notFoundPage.title')}
                  </h2>
                  <p className="max-w-sm text-base">
                    {getText('notFoundPage.subtitle')}
                  </p>
                </div>
              </Route>
            </Switch>
          </DashboardWrapper>
        </Route>
      </Switch>
      <FullstoryRecording />
    </div>
  );

  return app;
};

export default App;
