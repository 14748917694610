import React, { useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { IconX } from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { BaseModal } from '@noloco/components';
import { XL } from '@noloco/components/src/constants/tShirtSizes';
import { MONTH } from '@noloco/core/src/constants/accountPlanIntervals';
import {
  CUSTOMER_PORTAL,
  PlanProduct,
} from '@noloco/core/src/constants/accountPlanProducts';
import accountPlans, {
  AccountPlan,
  FREE,
} from '@noloco/core/src/constants/accountPlans';
import {
  DOWNGRADE,
  SUBSCRIPTION,
  UPGRADE,
} from '@noloco/core/src/constants/paymentStatus';
import { BillingPlan } from '@noloco/core/src/models/BillingPlan';
import { Project } from '@noloco/core/src/models/Project';
import { Team } from '@noloco/core/src/models/Team';
import { Usage } from '@noloco/core/src/models/Usage';
import { DashboardUser, User } from '@noloco/core/src/models/User';
import { setBillingPlan } from '@noloco/core/src/reducers/billingPlan';
import { setUser } from '@noloco/core/src/reducers/user';
import { planAddonsSelector } from '@noloco/core/src/selectors/billingPlanSelectors';
import { getDomain } from '@noloco/core/src/utils/emails';
import { getTextFromError } from '@noloco/core/src/utils/hooks/useAlerts';
import usePromiseQuery from '@noloco/core/src/utils/hooks/usePromiseQuery';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import confettiImage from '../../img/confetti.gif';
import {
  ADD_PAID_PLAN_QUERY,
  CHANGE_PLAN_QUERY,
  CONFIRM_PAYMENT_QUERY,
} from '../../queries/billing';
import { GET_TEAM_USAGE } from '../../queries/project';
import useTrackDashboardPage, {
  PageTypes,
} from '../../utils/hooks/useTrackDashboardPage';
import CancelSubscriptionModal from './CancelSubscriptionModal';
import UpgradeSubscriptionModal from './UpgradeSubscriptionModal';
import PlanConfiguration from './plan/config/PlanConfiguration';
import CostBreakdown from './plan/cost/CostBreakdown';

const PROMO_CODES: Record<string, number> = {
  MEOW50: 0.5,
  PH30: 0.3,
  PRODUCTHUNT50: 0.5,
};

const getReferralId = () => {
  const id = window.Rewardful && window.Rewardful.referral;
  return id ? id : undefined;
};

const findErrorByTitle = (error: any, type: any) =>
  error.graphQLErrors &&
  error.graphQLErrors.find(
    (gqlError: any) => get(gqlError, 'extensions.title') === type,
  );

type ChangePlanModalProps = {
  billingPlan: BillingPlan;
  hasPaymentMethod: boolean;
  onClose: () => void;
  project?: Project;
  selectedPlan: AccountPlan;
  teamUsage: Usage;
  workspace: Team;
  user: User | DashboardUser;
};

const ChangePlanModal = ({
  billingPlan,
  hasPaymentMethod,
  onClose,
  selectedPlan,
  teamUsage,
  workspace,
  user,
}: ChangePlanModalProps) => {
  useTrackDashboardPage(PageTypes.CHANGE_PLAN);
  const dispatch = useDispatch();
  const elements = useElements();
  const { pushQueryParams } = useRouter();
  const stripe = useStripe();

  const [addPlanMutation] = useMutation(ADD_PAID_PLAN_QUERY);
  const [changePlanMutation] = useMutation(CHANGE_PLAN_QUERY);
  const [confirmPaymentMutation] = useMutation(CONFIRM_PAYMENT_QUERY);

  const [getTeamUsage] = usePromiseQuery(GET_TEAM_USAGE, {
    fetchPolicy: 'no-cache',
  });

  const planAddons = useSelector(planAddonsSelector);

  const [errorMessage, setErrorMessage] = useState(null);
  const [internalDomains, setInternalDomains] = useState(
    planAddons?.externalUsers?.domains ?? [getDomain(user.email)],
  );
  const [interval, setPlanInterval] = useState(billingPlan.interval || MONTH);
  const [initialHasPaymentMethod] = useState(hasPaymentMethod);
  const [isUpgrading] = useState(
    accountPlans.indexOf(selectedPlan) > accountPlans.indexOf(billingPlan.type),
  );
  const [loading, setLoading] = useState(false);
  const [promotionCode, setPromotionCode] = useState<string | null>(null);
  const [promotionCodeValid, setPromotionCodeValid] = useState<boolean | null>(
    null,
  );
  const [recalculating, setRecalculating] = useState(false);
  const [showPostUpgradeModal, setShowPostUpgradeModal] = useState(false);
  const [success, setSuccess] = useState(false);

  const [teamUsageDraft, setTeamUsageDraft] = useState(teamUsage);

  const usageForCostBreakdown = useMemo(() => {
    const projects = get(teamUsageDraft, ['projects'], []);
    const seats = get(teamUsageDraft, ['plan', 'activeUsers'], 1);

    return {
      seats: Math.max(seats, 1),
      addons: projects
        .filter(({ addons }) => addons.externalUsers)
        .map(({ name, usage }) => ({
          project: name,
          external: usage.externalUsers,
        })),
    };
  }, [teamUsageDraft]);

  const balance = useMemo(
    () => Math.min(billingPlan.balance ?? 0, 0),
    [billingPlan],
  );

  const payKey = useMemo(() => {
    if (isUpgrading) {
      return 'upgrade';
    } else {
      return 'downgrade';
    }
  }, [isUpgrading]);

  const handleApplyPromotionCode = (promotionCodeText: string | null) => {
    if (promotionCodeText) {
      setPromotionCode(promotionCodeText);
      setPromotionCodeValid(!!PROMO_CODES[promotionCodeText]);
    } else {
      setPromotionCode(null);
      setPromotionCodeValid(null);
    }
  };

  const handleChangeProjectProduct = useCallback(
    (project: string) => (product: PlanProduct) => {
      setRecalculating(true);
      getTeamUsage({
        variables: {
          overrides: teamUsageDraft.projects.map(({ name, addons }) => ({
            projectName: name,
            hasExternalUsers:
              name === project
                ? product === CUSTOMER_PORTAL
                : addons.externalUsers,
            internalDomains,
          })),
        },
      }).then(({ data }) => {
        setTeamUsageDraft(data.teamUsage);
        setRecalculating(false);
      });
    },
    [getTeamUsage, internalDomains, teamUsageDraft.projects],
  );

  const handleChangeInternalDomains = useCallback(
    (newInternalDomains: string[]) => {
      setRecalculating(true);
      setInternalDomains(newInternalDomains);
      getTeamUsage({
        variables: {
          overrides: teamUsageDraft.projects.map(({ name, addons }) => ({
            projectName: name,
            hasExternalUsers: addons.externalUsers,
            internalDomains: newInternalDomains,
          })),
        },
      }).then(({ data }) => {
        setTeamUsageDraft(data.teamUsage);
        setRecalculating(false);
      });
    },
    [getTeamUsage, teamUsageDraft.projects],
  );

  const handleUpdateUserBilling = useCallback(
    (updatedUser) => {
      if (updatedUser) {
        const updatedBillingPlan = get(updatedUser, 'team.plan');
        dispatch(setBillingPlan({ ...billingPlan, ...updatedBillingPlan }));
        dispatch(setUser({ ...user, ...updatedUser }));

        if (!initialHasPaymentMethod && isUpgrading) {
          pushQueryParams({ status: SUBSCRIPTION });
        } else if (initialHasPaymentMethod && isUpgrading) {
          setShowPostUpgradeModal(true);
          pushQueryParams({ status: UPGRADE });
        } else {
          pushQueryParams({ status: DOWNGRADE });
        }
      }
    },
    [
      billingPlan,
      dispatch,
      initialHasPaymentMethod,
      isUpgrading,
      pushQueryParams,
      user,
    ],
  );

  const handleError = async (error: any) => {
    setLoading(false);
    const hasInvalidPromotionCode = findErrorByTitle(
      error,
      'Invalid promotion code',
    );
    const actionRequiredError = findErrorByTitle(
      error,
      'Payment requires action',
    );

    if (actionRequiredError) {
      setLoading(true);
      const {
        paymentIntent,
        error: confirmationError,
        // @ts-expect-error TS(2531): Object is possibly 'null'.
      } = await stripe.confirmCardPayment(
        actionRequiredError.extensions.data.clientSecret,
      );

      if (!confirmationError) {
        confirmPaymentMutation({
          variables: {
            paymentIntentId: paymentIntent.id,
            planType: selectedPlan,
          },
        })
          .then(({ data }) => {
            setSuccess(true);
            handleUpdateUserBilling(data?.confirmPaymentIntent.user);
          })
          .catch((paymentConfirmationError) =>
            handleError(paymentConfirmationError),
          )
          .finally(() => {
            setLoading(false);
          });
      } else {
        // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        setErrorMessage(confirmationError.message);
        setLoading(false);
      }
    } else {
      const { message } = getTextFromError(error);
      setErrorMessage(message || getText('billing.error'));
      if (hasInvalidPromotionCode) {
        setPromotionCodeValid(false);
        setPromotionCode(null);
      }
    }
  };

  const handleChangePlan = async () => {
    try {
      const { data } = await changePlanMutation({
        variables: {
          teamId: parseInt(workspace.id as string, 10),
          addons: teamUsageDraft.projects.map(({ name, addons }) => ({
            projectName: name,
            addon: {
              enabled: addons.externalUsers,
              domains: internalDomains,
            },
          })),
          interval,
          planType: selectedPlan,
        },
      });

      setLoading(false);
      setSuccess(true);
      handleUpdateUserBilling(data?.changePlan.user);
    } catch (e) {
      await handleError(e);
    }
  };

  const handleAddPaidPlan = async () => {
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      // @ts-expect-error TS(2322): Type 'StripeCardElement | null' is not assignable ... Remove this comment to see the full error message
      card: elements.getElement(CardElement),
      billing_details: {
        name: `${user.firstName} ${user.lastName}`,
      },
    });

    if (error) {
      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      setErrorMessage(error.message);
      setLoading(false);
    } else {
      try {
        const { data } = await addPlanMutation({
          variables: {
            teamId: parseInt(workspace.id as string, 10),
            addons: teamUsageDraft.projects.map(({ name, addons }) => ({
              projectName: name,
              addon: {
                enabled: addons.externalUsers,
                domains: internalDomains,
              },
            })),
            interval,
            paymentMethodId: paymentMethod.id,
            planType: selectedPlan,
            promotionCode,
            referralId: getReferralId(),
          },
        });

        setLoading(false);
        setSuccess(true);
        handleUpdateUserBilling(data?.addPaidPlan.user);
      } catch (e) {
        await handleError(e);
      }
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!success) {
      setLoading(true);
      setErrorMessage(null);
      if (hasPaymentMethod) {
        await handleChangePlan();
      } else {
        await handleAddPaidPlan();
      }
    } else {
      onClose();
    }
  };

  if (!isUpgrading && selectedPlan === FREE) {
    return (
      <CancelSubscriptionModal
        handleChangePlan={handleChangePlan}
        loading={loading}
        onClose={onClose}
      />
    );
  }

  if (showPostUpgradeModal) {
    return <UpgradeSubscriptionModal onClose={onClose} user={user} />;
  }

  return (
    <BaseModal onClose={() => null} size={XL}>
      <div className="flex w-full flex-col overflow-y-auto">
        <div
          className="flex items-center bg-white bg-repeat px-8 py-6 text-2xl font-medium text-gray-600"
          style={{
            backgroundImage:
              !loading && success && (isUpgrading || !initialHasPaymentMethod)
                ? `url('${confettiImage}')`
                : undefined,
          }}
        >
          <h1 className="">
            {getText(
              'billing.changePlan.title',
              hasPaymentMethod ? 'change' : 'add',
            )}
          </h1>
          <button
            disabled={loading}
            onClick={onClose}
            className={classNames('ml-auto text-gray-400', {
              'hover:text-gray-600': !loading,
            })}
          >
            <IconX size={18} />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="flex flex-col">
          <div className="flex flex-row space-x-8 overflow-y-auto bg-gray-100 p-8">
            <PlanConfiguration
              billingPlan={billingPlan}
              className="w-1/2"
              hasPaymentMethod={hasPaymentMethod}
              internalDomains={internalDomains}
              interval={interval}
              onApplyPromotionCode={handleApplyPromotionCode}
              onChangeInternalDomains={handleChangeInternalDomains}
              onChangeInterval={setPlanInterval}
              onChangeProduct={handleChangeProjectProduct}
              payKey={payKey}
              projects={teamUsageDraft.projects}
              promotionCodeValid={promotionCodeValid}
              recalculating={recalculating}
              selectedPlan={selectedPlan}
            />
            <CostBreakdown
              balance={balance}
              billingPlan={billingPlan}
              className="w-1/2"
              disabled={
                !stripe ||
                loading ||
                (selectedPlan === billingPlan.type &&
                  interval === billingPlan.interval)
              }
              discount={get(PROMO_CODES, [promotionCode as string], 1)}
              errorMessage={errorMessage}
              interval={interval}
              hasPaymentMethod={initialHasPaymentMethod}
              loading={loading}
              payKey={payKey}
              recalculating={recalculating}
              selectedPlan={selectedPlan}
              success={success}
              usage={usageForCostBreakdown}
            />
          </div>
        </form>
      </div>
    </BaseModal>
  );
};

export default ChangePlanModal;
