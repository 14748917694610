import React, { useState } from 'react';
import SplitLayout from '@noloco/components/src/components/auth/SplitLayout';
import SubmitButton from '@noloco/components/src/components/auth/SubmitButton';
import FormField from '@noloco/components/src/components/form/FormField';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { useDashboardAuth } from '@noloco/core/src/utils/hooks/useAuth';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import logoBlack from '../img/infinity-logo-black.svg';
import useTrackDashboardPage, {
  PageTypes,
} from '../utils/hooks/useTrackDashboardPage';

const ResetPassword = ({ history }: any) => {
  const {
    query: { resetToken },
  } = useRouter();
  const [errors, setErrors] = useState([]);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { resetPassword } = useDashboardAuth();

  useTrackDashboardPage(PageTypes.RESET_PASSWORD);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setErrors([]);
    try {
      resetPassword(email, password, confirmPassword, { resetToken })
        .then(() => {
          history.push(`/`);
        })
        .catch((error: any) => {
          if (error.graphQLErrors) {
            setErrors(error.graphQLErrors.map((er: any) => er.message));
          } else {
            // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'never'.
            setErrors(['Something went wrong, please try again']);
          }
          console.warn('ERROR', error);
        });
    } catch (error) {
      // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
      setErrors([(error as any).message]);
    }
  };

  return (
    <SplitLayout
      errors={errors}
      logoUrl={logoBlack}
      onSubmit={handleSubmit}
      titleText={getText('appAuth.resetPassword.title')}
      subTitleText={getText('appAuth.resetPassword.help')}
      splitChildren={
        <div className="flex h-full w-full items-center bg-pink-500 py-32">
          <div
            className="bg-top-left -mr-32 ml-auto h-full w-full max-w-5xl rounded-lg bg-cover bg-no-repeat lg:-mr-32"
            style={{
              backgroundImage: `url('https://media.noloco.app/384-noloco-portal-preview.png')`,
            }}
          ></div>
        </div>
      }
    >
      <FormField
        aria-label="Email address"
        autoComplete="email"
        name="email"
        type="email"
        onChange={({ target: { value } }: any) => setEmail(value)}
        required
        errorType="below-solid"
        label={getText('appAuth.resetPassword.email.label')}
        placeholder=""
        surface={LIGHT}
        value={email}
      />
      <FormField
        aria-label="Password"
        autoComplete="current-password"
        className="mt-4"
        name="password"
        type="password"
        onChange={({ target: { value } }: any) => setPassword(value)}
        required
        errorType="below-solid"
        label={getText('appAuth.resetPassword.password.label')}
        placeholder=""
        surface={LIGHT}
        value={password}
      />
      <FormField
        aria-label="Confirm password"
        className="mt-4"
        name="confirm-password"
        type="password"
        onChange={({ target: { value } }: any) => setConfirmPassword(value)}
        required
        errorType="below-solid"
        label={getText('appAuth.resetPassword.confirmPassword.label')}
        placeholder=""
        surface={LIGHT}
        value={confirmPassword}
      />
      <SubmitButton
        disabled={!password || !confirmPassword || confirmPassword !== password}
      >
        {getText('appAuth.resetPassword.confirm')}
      </SubmitButton>
    </SplitLayout>
  );
};

export default ResetPassword;
