import { IconArrowUpRight } from '@tabler/icons-react';
import kebabCase from 'lodash/kebabCase';
import startCase from 'lodash/startCase';
import { Loader } from '@noloco/components';
import { Template } from '@noloco/core/src/models/Template';
import { getText } from '@noloco/core/src/utils/lang';
import DataSourceIcon from './DataSourceIcon';
import TemplateCreator from './TemplateCreator';

const formatProjectName = (name: string) =>
  startCase(kebabCase(name).replace(/-/g, ' '));

type TemplatesProps = {
  setActiveTemplate: (activeTemplate: Template) => void;
  templates: Template[];
  templatesLoading: boolean;
};

const Templates = ({
  setActiveTemplate,
  templates,
  templatesLoading,
}: TemplatesProps) => (
  <>
    {templatesLoading && (
      <div className="flex h-80 w-full flex-col items-center justify-center p-8 text-center">
        <Loader size="sm" className="my-2 group-hover:text-cyan-500" />
        <p className="font-bold">{getText('newProject.loadingTemplates')}</p>
      </div>
    )}
    {!templatesLoading && templates.length === 0 && (
      <div className="flex h-80 w-full flex-col items-center justify-center p-8 text-center">
        <p className="font-bold">{getText('newProject.empty')}</p>
      </div>
    )}
    {templates.map((template: Template) => (
      <div
        key={template.airtableBaseLink}
        className="group h-96 w-full cursor-pointer justify-center rounded-lg border bg-white text-center ring-cyan-500 transition duration-150 ease-in-out hover:ring"
        onClick={() => setActiveTemplate(template)}
      >
        <div
          className="relative h-48 w-full overflow-hidden rounded-lg rounded-b-none rounded-bl-none bg-gray-500 bg-cover bg-center object-contain"
          style={{
            backgroundImage: `url(${template.thumbnail})`,
          }}
        >
          <div className="absolute bottom-4 left-4 flex items-center space-x-2">
            {template.projects.map((templateProject) => (
              <div
                className="flex h-8 w-8 items-center justify-center rounded-full bg-white p-1.5"
                key={template.id}
              >
                <DataSourceIcon
                  key={templateProject.id}
                  type={templateProject.dataSourceType}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="relative flex h-48 w-full flex-col overflow-hidden px-4 text-left">
          <div className="mb-2 mt-4 flex items-center">
            <h1 className="text-lg font-semibold">
              {template.title || formatProjectName(template.name)}
            </h1>
            <IconArrowUpRight
              size="16"
              className="order-2 ml-auto text-gray-400 group-hover:text-cyan-500"
            />
          </div>
          {template.description && (
            <p className="line-clamp-2 w-full text-sm text-gray-400">
              {template.description}
            </p>
          )}
          <TemplateCreator
            className="absolute bottom-4"
            creator={template.creator}
          />
        </div>
      </div>
    ))}
  </>
);

export default Templates;
