import React, { useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import get from 'lodash/get';
import partition from 'lodash/partition';
import {
  Button,
  FormField,
  Label,
  Loader,
  isEmailValid,
} from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { DashboardUser } from '@noloco/core/src/models/User';
import {
  getTextFromError,
  useInfoAlert,
} from '@noloco/core/src/utils/hooks/useAlerts';
import { useDashboardAuth } from '@noloco/core/src/utils/hooks/useAuth';
import useCacheQuery from '@noloco/core/src/utils/hooks/useCacheQuery';
import { getText } from '@noloco/core/src/utils/lang';
import { APP_DASHBOARD_URL } from '../../constants/env';
import { INVITES, INVITE_USER } from '../../queries/referrals';
import useTrackDashboardPage, {
  PageTypes,
} from '../../utils/hooks/useTrackDashboardPage';
import ReferredUser from './ReferredUser';

const LANG_KEY = 'referrals';

type InvitedUser = {
  createdAt: string;
  inviteAcceptedAt: string | null;
  user: DashboardUser;
};

const Referrals = () => {
  const infoAlert = useInfoAlert();

  useTrackDashboardPage(PageTypes.REFER);

  const { data: invitesData, loading, refetch } = useCacheQuery(INVITES);
  const { user } = useDashboardAuth();
  const [inviteUser, { loading: inviteLoading }] = useMutation(INVITE_USER);
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const referralLink = useMemo(() => {
    if (!user || !user.referralToken) {
      return null;
    }

    return `${APP_DASHBOARD_URL}/invite/${user.referralToken}`;
  }, [user]);
  const sendInvite = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!email) {
        return;
      }

      if (!isEmailValid(email)) {
        setErrorMessage(getText({ email }, LANG_KEY, 'errors.invalidEmail'));
        return;
      }

      inviteUser({ variables: { email } })
        .then(() => {
          setErrorMessage(null);
          refetch();
          setSuccessMessage(getText({ email }, LANG_KEY, 'success'));
          setEmail('');
        })
        .catch((e) => {
          console.log('Error inviting user', e);
          const { message: errorText } = getTextFromError(e);
          if (errorText) {
            setErrorMessage(errorText);
          } else {
            setErrorMessage(getText({ email }, LANG_KEY, 'errors.generic'));
          }
        });
    },
    [email, inviteUser, refetch],
  );

  const invitedUsers: InvitedUser[] = useMemo(
    () => get(invitesData, 'invites', []),
    [invitesData],
  );

  const [acceptedInvites, pendingInvites] = useMemo(
    () =>
      partition(invitedUsers, (invitedUser) => invitedUser.inviteAcceptedAt),
    [invitedUsers],
  );

  return (
    <div className="mx-auto flex max-w-screen-lg flex-col p-8">
      <h1 className="text-2xl font-medium">{getText(LANG_KEY, 'title')}</h1>
      <p className="mt-3 text-base">{getText(LANG_KEY, 'subtitle')}</p>
      <p className="mt-1 text-sm text-gray-700">
        {getText(LANG_KEY, 'explainer')}
      </p>
      {referralLink && (
        <div className="mt-6 flex w-full items-end space-x-2 sm:flex-col sm:space-x-0 sm:space-y-2">
          <FormField
            className="w-full"
            disabled={true}
            label={getText(LANG_KEY, 'link.label')}
            p={{ x: 4, y: 2 }}
            surface={LIGHT}
            value={referralLink}
          />
          <Button
            className="w-40 whitespace-nowrap disabled:opacity-75"
            onClick={() => {
              navigator.clipboard.writeText(referralLink);
              infoAlert(getText(LANG_KEY, 'link.copied'));
            }}
            p={{ x: 4, y: 2 }}
          >
            {getText(LANG_KEY, 'link.copy')}
          </Button>
        </div>
      )}
      <form
        className="mt-6 flex w-full items-end space-x-2 sm:flex-col sm:space-x-0 sm:space-y-2"
        onSubmit={sendInvite}
      >
        <FormField
          className="w-full"
          label={getText(LANG_KEY, 'email.label')}
          placeholder={getText(LANG_KEY, 'email.placeholder')}
          value={email}
          onChange={({ target: { value } }: any) => {
            setEmail(value);
          }}
          surface={LIGHT}
          p={{ x: 4, y: 2 }}
        />
        <Button
          className="w-40 whitespace-nowrap disabled:opacity-75"
          disabled={inviteLoading}
          submitFormOnClick={true}
          p={{ x: 4, y: 2 }}
        >
          {inviteLoading ? (
            <Loader size="xs" className="mx-auto my-0.5" />
          ) : (
            getText(LANG_KEY, 'email.send')
          )}
        </Button>
      </form>
      {(successMessage || errorMessage) && (
        <div className="mt-2 flex flex-col">
          <span
            className={classNames('text-sm font-medium', {
              'text-green-600': successMessage && !errorMessage,
              'text-red-600': errorMessage,
            })}
          >
            {errorMessage ?? successMessage}
          </span>
          <span className="mt-0.5 text-xs text-gray-500">
            {getText(LANG_KEY, 'message')}
          </span>
        </div>
      )}
      {loading && (
        <div className="flex h-48 w-full items-center justify-center">
          <Loader size="sm" />
        </div>
      )}
      {pendingInvites.length > 0 && (
        <div className="mt-6 flex flex-col">
          <Label surface={LIGHT}>{getText(LANG_KEY, 'invites.pending')}</Label>
          <div className="mt-2 flex flex-col space-y-2">
            {pendingInvites.map((invitedUser) => (
              <ReferredUser
                user={invitedUser.user}
                inviteAccepted={false}
                key={invitedUser.user.id}
              />
            ))}
          </div>
        </div>
      )}
      {acceptedInvites.length > 0 && (
        <div className="mt-6 flex flex-col">
          <Label surface={LIGHT}>{getText(LANG_KEY, 'invites.accepted')}</Label>
          <div className="mt-2 flex flex-col space-y-2">
            {acceptedInvites.map((invitedUser) => (
              <ReferredUser
                user={invitedUser.user}
                inviteAccepted={true}
                key={invitedUser.user.id}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Referrals;
