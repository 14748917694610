// We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/dzeqnadv'
function loadIntercom() {
  var w = window;
  if (w.intercomLoaded) {
    return;
  }

  var ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    const d = document;
    const i = function () {
      i.c(arguments);
    };
    i.q = [] as any[];
    i.c = function (args: any) {
      i.q.push(args);
    };
    w.Intercom = i;
    var l = function () {
      var s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.intercom.io/widget/dzeqnadv';
      var x = d.getElementsByTagName('script')[0];
      x.parentNode?.insertBefore(s, x);
      w.intercomLoaded = true;
    };
    if (document.readyState === 'complete') {
      l();
    } else if (w.attachEvent) {
      w.attachEvent('onload', l);
    } else {
      w.addEventListener('load', l, false);
    }
  }
}

try {
  const urlParams = new URLSearchParams(window.location.search);
  const ajsId = urlParams.get('ajs_anonymous_id');
  if (ajsId) {
    // The double quotation marks are intentional - it's how Segment sets it
    localStorage.setItem('ajs_anonymous_id', `"${ajsId}"`);
  }
} catch {
  console.log('Error setting ajs_anonymous_id');
}

if (process.env.NODE_ENV === 'production') {
  const analytics: any = (window.analytics = window.analytics || []);
  if (!analytics.initialize)
    if (analytics.invoked)
      window.console &&
        console.error &&
        console.error('Segment snippet included twice.');
    else {
      analytics.invoked = !0;
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
        'addSourceMiddleware',
        'addIntegrationMiddleware',
        'setAnonymousId',
        'addDestinationMiddleware',
      ];
      analytics.factory = function (e: any) {
        return function () {
          var t = Array.prototype.slice.call(arguments);
          t.unshift(e);
          analytics.push(t);
          return analytics;
        };
      };
      for (var e = 0; e < analytics.methods.length; e++) {
        var key = analytics.methods[e];
        analytics[key] = analytics.factory(key);
      }
      analytics.load = function (key: any, e: any) {
        var t = document.createElement('script');
        t.type = 'text/javascript';
        t.async = !0;
        t.src = 'https://seg.noloco.io/seg';
        const n = document.getElementsByTagName('script')[0];
        n.parentNode?.insertBefore(t, n);
        analytics._loadOptions = e;
      };
      analytics._writeKey = 'Tgl3QwxmBowfYJ20OrVlTA0RNpth73Rq';
      analytics.SNIPPET_VERSION = '4.15.3';
      analytics.load('Tgl3QwxmBowfYJ20OrVlTA0RNpth73Rq');
      analytics.page();
    }

  window.intercomSettings = {
    api_base: 'https://api-iam.intercom.io',
    app_id: 'dzeqnadv',
    action_color: '#ec4899',
    background_color: '#ec4899',
  };

  loadIntercom();
} else {
  window.analytics = {
    identify: () => null,
    page: () => null,
    track: () => null,
    addSourceMiddleware: () => null,
  };
}
